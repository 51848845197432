.detailed-poster-card-container {
    cursor: pointer;
    position: relative;
}

.detailed-poster-card-rating {
    position: absolute;
    top: .2rem;
    right: .2rem;
}

.detailed-poster-card-tray {
    padding: .2rem 0;
}
.detailed-poster-card-tray > * {
    padding: 0 .2rem;
}

.detailed-poster-card-tray .title {
    font-size: .9rem;
}

.detailed-poster-card-tray .title,
.detailed-poster-card-tray .subtitle {
    width: var(--poster-card-width); 
}

/* rating chart */
.detailed-poster-card-container .c100 {
    font-size: 1.6rem;
}

@media only screen and (min-width: 550px) {
    .detailed-poster-card-container .c100 {
        font-size: 1.8rem;
        margin: .05rem;
    }

    .detailed-poster-card-tray {
        padding: .3rem 0;
    }

    .detailed-poster-card-tray .title {
        font-size: 1rem;
        margin-bottom: .2rem;
    }
    
    .detailed-poster-card-tray .subtitle {
        font-size: .9rem;
    }

    /* 
    when the poster is larger, we dont want the title to wrap multiple lines,
    so limit the title and subtitle to 1 line 
    */
    .detailed-poster-card-tray .title,
    .detailed-poster-card-tray .subtitle {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

@media only screen and (min-width: 800px) {
    .detailed-poster-card-container .c100 {
        font-size: 2rem;
    }
}