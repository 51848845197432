/* 

Carousel sizing related styles are in posterCardCategoryRowSplide.css

*/


.poster-card-category-row-container {
    margin-bottom: .5rem;
}

.poster-card-category-row-title-container {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: 0 .8rem .8rem .8rem;
    font-size: 1rem;
}

.poster-card-category-row-nav-button-container {
    display: none;
}

@media only screen and (min-width: 550px) {
    .poster-card-category-row-title-container {
        font-size: 1.2rem;
        margin: 0 1.6rem .9rem 1.6rem;
    }

    .poster-card-category-row-nav-button-container {
        align-items: center;
        display: flex;
    }

    .poster-card-category-row-nav-button {
        font-size: 1.7rem;
        margin-left: .7rem;
    }
}

@media only screen and (min-width: 800px) {
    .poster-card-category-row-title-container {
        font-size: 1.3rem;
        margin: 0 1.6rem .9rem 1.6rem;
    }

    .poster-card-category-row-nav-button-container {
        margin-right: .2rem;
    }

    .poster-card-category-row-nav-button {
        font-size: 1.9rem;
        margin-left: .8rem;
    }
}